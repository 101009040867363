<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" disable-button />
    <v-card class="rounded-card" flat outlined>
      <v-card-text class="mt-5 mb-3 px-12">
        <app-form ref="form" :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"
          disableCancelButton />
      </v-card-text>
      </v-card>
  </v-container>
</template>

<script>
import {userRoles} from 'constants';
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      title: {
        text: this.$t('layout.adminMenu.invitation'),
        icon: 'mdi-card-account-mail'
      },
      breadcrumbs: [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$tc('models.user.entity', 2),
        to: {name: 'Users'},
        exact: true
      },
      {
        text: this.$t('layout.adminMenu.invitation'),
        to: {name: 'UserInvitation'},
        exact: true
      }],
      fields: {
        firstName: {
          type: 'text',
          key: 'firstName',
          value: '',
          label: this.$t('models.user.attributes.firstName'),
          rules: [{
            name: 'required'
          }],
          classes: 'col-lg-6 col-12 py-0',
          serverErrors: []
        },
        lastName: {
          type: 'text',
          key: 'lastName',
          value: '',
          label: this.$t('models.user.attributes.lastName'),
          rules: [{
            name: 'required'
          }],
          classes: 'col-lg-6 col-12 py-0',
          serverErrors: []
        },
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.user.attributes.email'),
          rules: [{
            name: 'required'
          }, {
            name: 'email'
          }],
          classes: 'col-12 py-0',
          serverErrors: []
        },
        officeId: {
          type: 'select',
          key: 'officeId',
          value: '',
          options: [],
          itemText: 'name',
          itemValue: 'id',
          rules: [{name: 'requiredSelect'}],
          trackable: true,
          label: this.$t('models.user.attributes.office'),
          classes: 'col-12 py-0',
          serverErrors: []
        },
        role: {
          type: 'select',
          key: 'role',
          value: '',
          label: this.$t('models.user.attributes.role'),
          rules: [{name: 'requiredSelect'}],
          options: [],
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-12 py-0',
          serverErrors: []
        }
      },
      submitButton: {
        label: this.$t('actions.send'),
        icon: 'mdi-send'
      }
    };
  },
  computed: mapGetters(['offices']),
  methods: {
    ...mapActions(['inviteUser', 'getOffices']),
    handleSubmit(data) {
      this.inviteUser({...data, role: +data.role}).then((response) => {
        if (!this.successCode(response.status)) {
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    }
  },
  created() {
    this.getOffices({fields: ['name']}).then(() => {
      this.fields.officeId.options = this.offices.items;
    });
    let i = 0;
    const $this = this;
    for (const key in userRoles) {
      if (Object.prototype.hasOwnProperty.call(userRoles, key)) {
        $this.fields.role.options.push({text: $this.$t(`models.user.roles.${userRoles[key]}`), key: `${i}`});
        i++;
      }
    }
  }
};
</script>
